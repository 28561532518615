<template>
  <div>

    <b-form-group
      label="Référence de colis"
      label-for="vi-first-name"
    >
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="MaximizeIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="ref"
          autofocus
          placeholder="Scan un colis"
          @change="checkOrder"
        />
      </b-input-group>
    </b-form-group>
    <!-- table -->
    <div class="d-flex justify-content-between">
      <b-card>
        <h2>Colis en attente de confirmation</h2>
        Nombre des colis : {{ ordersNotAccepted.length }}
        <vue-good-table
          :columns="columns"
          :rows="ordersNotAccepted"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <feather-icon
                  :id="`invoice-row-${props.row.id}-show-icon`"
                  icon="CheckSquareIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                  color="green"
                  @click="addItem(props.row)"
                />
                <b-tooltip
                  title="Ajouter"
                  class="cursor-pointer"
                  :target="`invoice-row-${props.row.id}-show-icon`"
                />

              </span>
            </span>

            <!-- Column: Common -->

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <b-card class="ml-1">
        <h2>Colis confirmé</h2>
        Nombre des colis : {{ ordersAccepted.length }}
        <vue-good-table
          :columns="columns"
          :rows="ordersAccepted"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <feather-icon
                  :id="`invoice-row-${props.row.id}-show-icon`"
                  icon="DeleteIcon"
                  color="red"
                  class="cursor-pointer mr-1"
                  size="16"
                  @click="resetItem(props.row)"
                />
                <b-tooltip
                  title="Annuler"
                  class="cursor-pointer"
                  :target="`invoice-row-${props.row.id}-show-icon`"
                />
              </span>
            </span>

            <!-- Column: Common -->

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

        <b-form-group class="d-flex justify-content-end">
          <b-button
            variant="gradient-primary"
            class="ml-1 mt-1 d-inline-block"
            :class="{ 'disabled-cursor': isLoading || !isInterAgencyValid }"
            :disabled="isLoading || !isInterAgencyValid"
            @click="validate"
          >
            <feather-icon icon="PlusIcon" />
            <span class="ml-1">Enregistrer</span>
          </b-button>
        </b-form-group>
      </b-card>

    </div>
  </div>
</template>

<script>
import {
  BCard, BButton, BPagination, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormSelect, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
// import flatPickr from 'vue-flatpickr-component'

import storeAuth from '@/store/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'

export default {
  name: 'ValidateInterReturn',
  components: {
    BButton,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    // flatPickr,
    BPagination,
    BTooltip,
    BCard,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      deliveryMans: [],
      deliveryMan: '',
      repositories: [],
      toRepositories: [],
      repository: '',
      toRepository: '',
      date: '01-10-2021',
      status: '',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'BL',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'BL',
          },
          width: '120px',
        },
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Client',
          },
        },
        {
          label: 'Gouvernorat',
          field: 'governorate',
          filterOptions: {
            enabled: true,
            placeholder: 'Gouvernorat',
          },
        },
        {
          label: 'Telephone',
          field: 'phone1',
          filterOptions: {
            enabled: true,
            placeholder: 'Telephone',
          },
        },
      ],
      role: '',
      ordersAccepted: [],
      ordersNotAccepted: [],
      searchTerm: '',
      ref: '',
      user: {},
      isLoading: false,
      id: '',
    }
  },
  computed: {
    isInterAgencyValid() {
      return (
        this.ordersAccepted.length > 0
        && this.user.repository !== ''
      )
    },
  },
  created() {
    this.user = storeAuth.state.user
    this.getOrders()
  },
  methods: {
    exists(id) {
      return this.items.some(el => el.code === Number(id))
    },
    withTitle(text) {
      this.$swal({
        title: 'Alerte',
        icon: 'warning',
        text,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        this.ref = ''
      })
    },
    async addItem(item) {
      this.ordersAccepted.push(item)
      const i = this.ordersNotAccepted.findIndex(element => element.id === item.id)
      this.ordersNotAccepted.splice(i, 1)
    },
    async resetItem(item) {
      this.ordersNotAccepted.push(item)
      const i = this.ordersAccepted.findIndex(element => element.id === item.id)
      this.ordersAccepted.splice(i, 1)
    },
    async getOrders() {
      try {
        this.isLoading = true
        const { data } = await axios.get(`/api/return-vouchers/inter-agency-return/detail-orders/${this.$route.params.id}`)
        this.ordersNotAccepted = data.orders.filter(element => element.status === 10)
        // this.toRepository = data.to_repository
        // this.id = data.id
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        setTimeout(() => {
          this.showToast('danger', 'top-center', 'network error: ')
        }, 1000)
      }
    },
    async validate() {
      this.isLoading = true
      const ordersAccepted = this.ordersAccepted.map(item => item.id)

      try {
        await axios.post('/api/return-vouchers/inter-agency-return/validate/', {
          orders: ordersAccepted,
          interAgencyReturnVoucher: this.$route.params.id,
        })
        this.$router.push('/bons-retours/inter-agence')
      } catch (error) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', 'network error: ')
        }, 1000)
      }
    },
    checkOrder() {
      const result = this.ordersNotAccepted.find(element => element.id === this.ref)
      const selected = this.ordersAccepted.find(element => element.id === this.ref)

      if (result) {
        this.addItem(result).then(() => { this.ref = '' })
      } else if (selected) {
        this.withTitle('Colis déja scanné')
      } else {
        this.withTitle('Colis non exist')
      }
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },

  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
